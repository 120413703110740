import { FC, useMemo } from 'react';
import { SvgIcon } from '../svg-icon';

import {
    WrapperItemWrapper,
    WorkshopHeader,
    WorkshopName,
    WorkshopAddress,
    WorkshopBody,
    DateHeader,
    DateInfo,
    LatestVisit,
    LatestVisitText,
    DateWrapper,
} from './workshop-card.styled';
import { IWorkshopCard } from './workshop-card.props';
import { Spinner } from '../spinner';
import { ShiftBy } from '../shift-by';
import { DateStyle, formatDate } from '../../../utils/helpers';
import { compareAsc } from 'date-fns';

export const WorkshopCard: FC<IWorkshopCard> = ({ workshop, setAsSelected, isSelected, isLoading, lastVisited, workshopContent }) => {
    const { id, name, address, availableDays } = workshop;

    const nextAvailableDate = useMemo(() => {
        const sortedDates = availableDays.sort((a, b) => a.date.getTime() - b.date.getTime());
        return sortedDates
            .filter(({ timeSlots, date }) => timeSlots.some(({ available }) => available) && compareAsc(date, new Date()) === 1)
            .map(({ date }) => date)
            .find(() => true);
    }, [availableDays]);

    return (
        <WrapperItemWrapper isSelected={isSelected} onClick={() => setAsSelected(id)}>
            <WorkshopHeader>
                <WorkshopName>{name}</WorkshopName>
                {lastVisited && (
                    <LatestVisit>
                        <LatestVisitText>
                            {workshopContent.preferredWorkshopText ? workshopContent.preferredWorkshopText : 'Sidste besøgt'}
                        </LatestVisitText>
                        <SvgIcon iconName="car/star" />
                    </LatestVisit>
                )}
            </WorkshopHeader>
            <WorkshopBody>
                <WorkshopAddress>{address}</WorkshopAddress>

                <DateWrapper>
                    <DateHeader>Næste ledige dato:</DateHeader>

                    {isLoading ? (
                        <ShiftBy x={10} y={5}>
                            <Spinner variant="light" size="small" />
                        </ShiftBy>
                    ) : (
                        <DateInfo>
                            {nextAvailableDate
                                ? formatDate(nextAvailableDate, DateStyle.simple_danish)
                                : workshopContent.longWaitingTimeText
                                ? workshopContent.longWaitingTimeText
                                : 'Mere end en måned'}
                        </DateInfo>
                    )}
                </DateWrapper>
            </WorkshopBody>
        </WrapperItemWrapper>
    );
};
