import { useEffect, useMemo, useState } from 'react';
import { DeliveryType } from '../../../lib';
import { BookingContactInfoStore } from '../../../lib/state/booking/contact-info';
import { BookingFormStore } from '../../../lib/state/booking/form';
import { BookingServiceProductsStore } from '../../../lib/state/booking/service-products';
import { BookingWorkshopsStore } from '../../../lib/state/booking/workshops';
import { removeDuplicateProducts } from '../../../utils/helpers';

type GTMData = {
    workshop: {
        name: string;
        id: string;
    };
    deliveryOption: string;
    serviceAgreement: string;
    products: GTMProducts[];
    newsletterOption: string;
    brand: string;
    revenue: number;
    tax: number;
    bookingAppointmentReference?: string;
};

type GTMProducts = {
    name: string;
    id: string;
    brand: string;
    category: string;
    variant: string;
    quantity: number;
    price: number;
};

export const useGTMBookingData = (): GTMData | undefined => {
    const [data, setData] = useState<GTMData>();

    const { isTermsAccepted } = BookingContactInfoStore.useStoreState((state) => state);
    const { vehicle, bookingAppointmentReference } = BookingFormStore.useStoreState((state) => state);
    const { selectedServiceProductsIds, flattenedProductsAndSubProducts, flattenedProductsOptions } = BookingServiceProductsStore.useStoreState(
        (state) => state
    );
    const { deliveryType, selectedWorkshop, workshops } = BookingWorkshopsStore.useStoreState((state) => state);

    const newsletterOption = useMemo(() => (isTermsAccepted ? 'Tilmeldt nyhedsbrev' : 'Ikke tilmeldt nyhedsbrev'), [isTermsAccepted]);

    const gtmProducts = useMemo(() => {
        const uniqueProductsAndSubProducts = removeDuplicateProducts(flattenedProductsAndSubProducts); // This is necessary because "Dækopbevaring" is a duplicated product
        const gtmProductsWithoutOptions = uniqueProductsAndSubProducts
            .filter((product) => (product.productOptions ?? []).length === 0)
            .filter(({ id }) => selectedServiceProductsIds.some((product) => product === id))
            .map(({ id, name, description, price }) => ({
                name: name,
                id: id,
                price: price?.value ?? 0,
                brand: vehicle?.make ?? '',
                variant: description,
                category: 'Servicebooking',
                quantity: 1,
            }));

        const selectedOptions = flattenedProductsOptions.filter(({ id }) => selectedServiceProductsIds.some((product) => product === id));
        const gtmProductsWithOptions = [];
        for (const selectedOption of selectedOptions) {
            const parentProduct = uniqueProductsAndSubProducts.find((product) =>
                [...(product.productOptions ?? []), product.defaultOption].some((option) => selectedOption.id === option?.id)
            );
            if (!parentProduct) continue;

            gtmProductsWithOptions.push({
                name: parentProduct.name,
                id: selectedOption.id,
                price: selectedOption?.price?.value ?? 0,
                brand: vehicle?.make ?? '',
                variant: selectedOption.name,
                category: 'Servicebooking',
                quantity: 1,
            });
        }
        return [...gtmProductsWithoutOptions, ...gtmProductsWithOptions];
    }, [flattenedProductsAndSubProducts, flattenedProductsOptions, selectedServiceProductsIds, vehicle?.make]);

    const revenue = useMemo(() => gtmProducts.reduce((sum, gtmProduct) => sum + gtmProduct.price, 0), [gtmProducts]);
    const tax = useMemo(() => Math.round(revenue * 0.2 * 100) / 100, [revenue]);

    const deliveryOption = useMemo(() => {
        switch (deliveryType) {
            case DeliveryType.SelfDeliverAndPickup:
                return 'Afleverer bil morgen og henter eftermiddag';
            case DeliveryType.CustomerStays:
                return 'Venter ved Ejner Hessel';
            case DeliveryType.ByVendor:
                return 'Mercedes el-bil - hent/bring';
        }
    }, [deliveryType]);

    useEffect(() => {
        setData({
            newsletterOption,
            deliveryOption,
            workshop: workshops
                .map((workshop) => ({ id: workshop.id.toString(), name: workshop.name }))
                .find(({ id }) => id === selectedWorkshop?.toString()) ?? { name: '', id: '' },
            products: gtmProducts,
            serviceAgreement: vehicle?.serviceAgreement ?? '',
            brand: vehicle?.make ?? '',
            revenue,
            tax,
            bookingAppointmentReference,
        });
    }, [
        bookingAppointmentReference,
        deliveryOption,
        newsletterOption,
        gtmProducts,
        revenue,
        selectedWorkshop,
        tax,
        workshops,
        vehicle?.make,
        vehicle?.serviceAgreement,
    ]);

    return data;
};
