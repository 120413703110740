import { action, computed, thunk } from 'easy-peasy';
import { createContextStoreWithRuntimeModel } from '../../helpers/context-store.helpers';
import { WorkshopsInjections, WorkshopsActions, WorkshopsState, WorkshopsStore, WorkshopsThunks, Workshop } from './booking-workshops.types';
import { add, parseISO } from 'date-fns';
import { DeliveryType } from '../../../enums';
import { getWorkshops, getWorkshopServiceAppointmentAvailability, hesselApi } from '../../../api';
import { SetAvailableDaysParams } from '../../../api/models/hessel-api';
import { generateServiceTooLongAppointmentAvailability } from '../../../../utils/helpers/booking/workshop.helper';
import { DateStyle, formatDate, mapTimeOnlyToDuration } from '../../../../utils/helpers';

const bookingWorkshopDefaultState = (): WorkshopsState => ({
    deliveryType: DeliveryType.CustomerStays,
    customerHasChosenDeliveryType: false,
    loadingWorkshops: false,
    selectedDate: new Date(),
    workshops: [],
    workshopStepIsValid: computed(
        ({ selectedWorkshop, selectedTimeSlot, selectedTimeSlotToStay, selectedDate, deliveryType, customerHasChosenDeliveryType }) => {
            if (deliveryType === DeliveryType.ByVendor) return true;

            if (selectedTimeSlot?.startDateTime === undefined && selectedTimeSlotToStay?.startDateTime === undefined) return false;
            return (selectedTimeSlot && selectedWorkshop && selectedDate && customerHasChosenDeliveryType) === true;
        }
    ),
    calendarShouldUpdate: false,
    isLoadingSelectedWorkshop: false,
});

const bookingWorkshopActions = (): WorkshopsActions => ({
    setAvailableDays: action((state, payload) => {
        payload.forEach((x) => {
            const workshopToModify = state.workshops.find((ws) => ws.id === x.id);
            if (workshopToModify) {
                workshopToModify.loadingDays = false;
                const daysToAddMapped = x.availableDays.map(({ date, timeSlots }) => ({
                    date: parseISO(date),
                    timeSlots: timeSlots.map(({ startDateTime, ...rest }) => ({
                        ...rest,
                        endDateTime: undefined,
                        startDateTime: parseISO(startDateTime),
                    })),
                }));
                if (x.concatToExistingDays) {
                    workshopToModify.availableDays = [...daysToAddMapped, ...workshopToModify.availableDays];
                } else {
                    workshopToModify.availableDays = [...daysToAddMapped];
                }
            }
        });
    }),
    setAvailableDaysToStay: action((state, payload) => {
        payload.forEach((x) => {
            const workshopToModify = state.workshops.find((ws) => ws.id === x.id);
            if (workshopToModify) {
                workshopToModify.loadingDays = false;
                const daysToAddMapped = x.availableDays.map(({ date, timeSlots }) => ({
                    date: parseISO(date),
                    timeSlots: timeSlots.map((timeSlot) => ({
                        ...timeSlot,
                        endDateTime: add(parseISO(timeSlot.startDateTime), mapTimeOnlyToDuration(timeSlot.duration)),
                        startDateTime: parseISO(timeSlot.startDateTime),
                    })),
                }));
                if (x.concatToExistingDays) {
                    workshopToModify.availableDaysToStay = [...daysToAddMapped, ...workshopToModify.availableDaysToStay];
                } else {
                    workshopToModify.availableDaysToStay = [...daysToAddMapped];
                }
            }
        });
    }),
    setDeliveryType: action((state, payload) => {
        state.deliveryType = payload;
    }),
    setCustomerHasChosenDeliveryType: action((state, payload) => {
        state.customerHasChosenDeliveryType = payload;
    }),
    setLoadingWorkshops: action((state, payload) => {
        state.loadingWorkshops = payload;
    }),
    setRentalCar: action((state, payload) => {
        state.rentalCar = payload;

        // OwnRisk is dependant on whether RentalCar is selected.
        state.ownRisk = payload;
    }),
    setOwnRisk: action((state, payload) => {
        state.ownRisk = payload;
    }),
    setSelectedDate: action((state, payload) => {
        state.selectedTimeSlot = undefined;
        state.selectedDate = payload;
    }),
    setSelectedWorkshop: action((state, payload) => {
        const workshopIsInWorkshopsList = state.workshops.some((workshop) => workshop.id === payload.id);
        if (!workshopIsInWorkshopsList) return;

        if (state.selectedWorkshop !== payload.id) {
            state.selectedWorkshop = payload.id;
            state.selectedTimeSlot = undefined;
        }
        if (payload.isFavourite) {
            state.favouriteWorkshop = payload.id;
        }
    }),
    setTimeSlot: action((state, payload) => {
        state.selectedTimeSlot = payload;
    }),
    setTimeSlotToStay: action((state, payload) => {
        state.selectedTimeSlotToStay = payload;
    }),
    setWorkshops: action((state, payload) => {
        state.workshops = payload;
    }),
    setCalendarShouldUpdate: action((state, payload) => {
        state.calendarShouldUpdate = payload;
    }),
    setShopToGetNearbyFrom: action((state, payload) => {
        state.shopToGetNearbyFrom = payload;
    }),
    cancelApiCalls: action((state) => {
        if (state.abortController) {
            state.abortController.abort();
        }
    }),
    createNewAbortController: action((state) => {
        state.abortController = new AbortController();
        state.workshops.forEach((x) => {
            x.loadingDays = false;
            x.availableDays = [];
            x.availableDaysToStay = [];
        });
        state.selectedTimeSlot = undefined;
        state.selectedTimeSlotToStay = undefined;
    }),
    setIsLoadingSelectedWorkshop: action((state, payload) => {
        state.isLoadingSelectedWorkshop = payload;
    }),
});

const bookingWorkshopThunks = (): WorkshopsThunks => ({
    getAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const storeState = getStoreState();

        if (payload.fullReload) {
            // Consider doing this in the initialization for getWorkshopsThunk
            storeState.workshops.forEach((x) => (x.loadingDays = true));
        }

        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        if (currentWorkshop) {
            currentWorkshop.loadingDays = true;
        }

        const availableDaysToAdd: SetAvailableDaysParams[] = [];
        const availableDaysToStayToAdd: SetAvailableDaysParams[] = [];

        if (!storeState.abortController) {
            storeState.abortController = new AbortController();
        }

        const { signal } = storeState.abortController;
        if (payload.serviceTooLong && payload.serviceTooLongLeadTime) {
            const leadTime = payload.serviceTooLongLeadTime(payload.apiPayload.workshopId);
            const availableDays = generateServiceTooLongAppointmentAvailability(
                leadTime,
                parseISO(payload.apiPayload.dateFrom),
                parseISO(payload.apiPayload.dateTo),
                payload.serviceTooLongHolidays
            );

            const availableDaysParam = {
                id: payload.apiPayload.workshopId,
                availableDays: availableDays,
                concatToExistingDays: !payload.fullReload,
            };
            availableDaysToAdd.push(availableDaysParam);
            availableDaysToStayToAdd.push(availableDaysParam);
        } else {
            const availableDaysPromise = getWorkshopServiceAppointmentAvailability(
                {
                    ...payload.apiPayload,
                },
                signal
            );

            const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                {
                    ...payload.apiPayload,
                    customerStays: true,
                },
                signal
            );

            const [[availableDays, availableDaysError], [availableDaysToStay, availableDaysToStayError]] = await Promise.all([
                availableDaysPromise,
                availableDaysToStayPromise,
            ]);

            if (availableDays && !availableDaysError) {
                availableDaysToAdd.push({
                    id: payload.apiPayload.workshopId,
                    availableDays: availableDays.appointmentAvailabilities,
                    concatToExistingDays: !payload.fullReload,
                });
            } else if (availableDaysError) {
                pushError(availableDaysError.errorType);
            }

            if (availableDaysToStay && !availableDaysToStayError) {
                availableDaysToStayToAdd.push({
                    id: payload.apiPayload.workshopId,
                    availableDays: availableDaysToStay.appointmentAvailabilities,
                    concatToExistingDays: !payload.fullReload,
                });
            } else if (availableDaysToStayError) {
                pushError(availableDaysToStayError.errorType);
            }
        }

        actions.setAvailableDays(availableDaysToAdd);
        actions.setAvailableDaysToStay(availableDaysToStayToAdd);
    }),

    getNearbyStoresAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const storeState = getStoreState();
        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        let availableDaysToAdd: SetAvailableDaysParams[] = [];
        let availableDaysToStayToAdd: SetAvailableDaysParams[] = [];

        // Nearby stores
        if (currentWorkshop?.nearByStores) {
            if (!storeState.abortController) {
                storeState.abortController = new AbortController();
            }

            const { signal } = storeState.abortController;

            const storesToLoad = storeState.workshops
                .filter((ws) => currentWorkshop.nearByStores.some((store) => store.id === ws.id))
                .filter((ws) => ws.availableDays.length === 0);

            for (const nearByStore of storesToLoad) {
                if (payload.serviceTooLong && payload.serviceTooLongLeadTime) {
                    const leadTime = payload.serviceTooLongLeadTime(nearByStore.id);
                    const availableDaysParam = {
                        id: nearByStore.id,
                        availableDays: generateServiceTooLongAppointmentAvailability(
                            leadTime,
                            parseISO(payload.apiPayload.dateFrom),
                            parseISO(payload.apiPayload.dateTo),
                            payload.serviceTooLongHolidays
                        ),
                        concatToExistingDays: false,
                    };

                    availableDaysToAdd.push(availableDaysParam);
                    availableDaysToStayToAdd.push(availableDaysParam);
                } else {
                    const availableDaysPromise = getWorkshopServiceAppointmentAvailability(
                        {
                            ...payload.apiPayload,
                            workshopId: nearByStore.id,
                        },
                        signal
                    );

                    const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                        {
                            ...payload.apiPayload,
                            workshopId: nearByStore.id,
                            customerStays: true,
                        },
                        signal
                    );

                    const [[days, daysError], [daysToStay, daysToStayError]] = await Promise.all([availableDaysPromise, availableDaysToStayPromise]);

                    if (days && !daysError) {
                        availableDaysToAdd.push({
                            id: nearByStore.id,
                            availableDays: days.appointmentAvailabilities,
                            concatToExistingDays: false,
                        });
                    } else if (daysError) {
                        pushError(daysError.errorType);
                    }

                    if (daysToStay && !daysToStayError) {
                        availableDaysToStayToAdd.push({
                            id: nearByStore.id,
                            availableDays: [...daysToStay.appointmentAvailabilities],
                            concatToExistingDays: false,
                        });
                    } else if (daysToStayError) {
                        pushError(daysToStayError.errorType);
                    }
                }

                actions.setAvailableDays(availableDaysToAdd);
                actions.setAvailableDaysToStay(availableDaysToStayToAdd);

                availableDaysToAdd = [];
                availableDaysToStayToAdd = [];
            }
        }
    }),

    getNonPreferredStoreAvailableDaysThunk: thunk(async (actions, payload, { injections: { pushError }, getStoreState }) => {
        const today = new Date();

        const storeState = getStoreState();

        const currentWorkshop = storeState.workshops.find((x) => x.id === payload.apiPayload.workshopId);

        const currentNearbyStoreIds = currentWorkshop?.nearByStores.map((nearbyStore) => nearbyStore.id);

        const workshopList = storeState.workshops.filter(
            (otherWorkshop) => otherWorkshop.id !== currentWorkshop?.id && !currentNearbyStoreIds?.includes(otherWorkshop.id)
        );

        let availableDaysToAdd: SetAvailableDaysParams[] = [];
        let availableDaysToStayToAdd: SetAvailableDaysParams[] = [];

        if (!storeState.abortController) {
            storeState.abortController = new AbortController();
        }
        const { signal } = storeState.abortController;

        for (const workshop of workshopList) {
            if (payload.serviceTooLong && payload.serviceTooLongLeadTime) {
                const leadTime = payload.serviceTooLongLeadTime(workshop.id);
                const availableDaysParam = {
                    id: workshop.id,
                    availableDays: generateServiceTooLongAppointmentAvailability(
                        leadTime,
                        parseISO(payload.apiPayload.dateFrom),
                        parseISO(payload.apiPayload.dateTo),
                        payload.serviceTooLongHolidays
                    ),
                    concatToExistingDays: false,
                };
                availableDaysToAdd.push(availableDaysParam);
                availableDaysToStayToAdd.push(availableDaysParam);
            } else {
                const availableDaysPromise = getWorkshopServiceAppointmentAvailability(
                    {
                        ...payload.apiPayload,
                        workshopId: workshop.id,
                        dateFrom: formatDate(today, DateStyle.yyyy_mm_dd),
                        dateTo: formatDate(add(today, { months: 2 }), DateStyle.yyyy_mm_dd),
                    },
                    signal
                );

                const availableDaysToStayPromise = getWorkshopServiceAppointmentAvailability(
                    {
                        ...payload.apiPayload,
                        dateFrom: formatDate(today, DateStyle.yyyy_mm_dd),
                        dateTo: formatDate(add(today, { months: 2 }), DateStyle.yyyy_mm_dd),
                        workshopId: workshop.id,
                        customerStays: true,
                    },
                    signal
                );

                const [[availableDays, availableDaysError], [availableDaysToStay, availableDaysToStayError]] = await Promise.all([
                    availableDaysPromise,
                    availableDaysToStayPromise,
                ]);

                if (availableDays && !availableDaysError) {
                    availableDaysToAdd.push({
                        id: workshop.id,
                        availableDays: availableDays.appointmentAvailabilities,
                        concatToExistingDays: false,
                    });
                } else if (availableDaysError) {
                    pushError(availableDaysError.errorType);
                }

                if (availableDaysToStay && !availableDaysToStayError) {
                    availableDaysToStayToAdd.push({
                        id: workshop.id,
                        availableDays: availableDaysToStay.appointmentAvailabilities,
                        concatToExistingDays: false,
                    });
                } else if (availableDaysToStayError) {
                    pushError(availableDaysToStayError.errorType);
                }
            }

            actions.setAvailableDays(availableDaysToAdd);
            actions.setAvailableDaysToStay(availableDaysToStayToAdd);

            availableDaysToAdd = [];
            availableDaysToStayToAdd = [];
        }
    }),

    getWorkshopsThunk: thunk(async (actions, payload, { injections: { pushError } }) => {
        actions.setLoadingWorkshops(true);

        const [result, error] = await getWorkshops(payload);
        if (result && !error) {
            const workshops: Workshop[] = result.workshops.map(({ nearbyWorkshopIds, ...workshopResponse }) => ({
                ...workshopResponse,
                availableDays: [],
                availableDaysToStay: [],
                loadingDays: false,
                id: workshopResponse.id,
                nearByStores: nearbyWorkshopIds
                    .map((nearbyId) => result.workshops.find((workshopId) => workshopId.id === nearbyId))
                    .filter((workshop) => workshop !== undefined)
                    .map((x) => x as hesselApi.Workshop),
            }));

            actions.setWorkshops(workshops);
            if (payload.preferredWorkshopId) actions.setSelectedWorkshop({ id: payload.preferredWorkshopId, isFavourite: true });
        } else if (error) {
            pushError(error.errorType);
        }

        actions.setLoadingWorkshops(false);
    }),
});

export const BookingWorkshopsStore = createContextStoreWithRuntimeModel<WorkshopsStore, WorkshopsState, WorkshopsInjections>(
    () => ({
        ...bookingWorkshopDefaultState(),
        ...bookingWorkshopActions(),
        ...bookingWorkshopThunks(),
    }),
    { name: 'Workshops' }
);
