import { FC, useMemo } from 'react';
import { DeliveryType } from '../../../../../lib';
import { umbraco } from '../../../../../lib/api';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking/workshops';
import { DateStyle, formatDate, getPickupTime, getTimeSlotEnd } from '../../../../../utils/helpers';
import { SubmittedInfo } from '../../../../shared/submitted-info';
import { CompletedWrapper } from './workshop-completed.styled';

type Props = {
    workshopContent: umbraco.BookingStepWorkshop;
};

export const CompletedWorkshop: FC<Props> = ({ workshopContent }) => {
    const { selectedDate, selectedWorkshop, selectedTimeSlot, workshops, deliveryType, selectedTimeSlotToStay } = BookingWorkshopsStore.useStoreState(
        (state) => state
    );
    const workshop = useMemo(() => workshops.find(({ id }) => id === selectedWorkshop), [workshops, selectedWorkshop]);

    const pickUpMethodDescription = useMemo(() => {
        const timeSlotToDisplay = deliveryType === DeliveryType.CustomerStays ? selectedTimeSlotToStay : selectedTimeSlot;
        if (!timeSlotToDisplay) return '';

        switch (deliveryType) {
            case DeliveryType.ByVendor:
                return workshopContent.pickupMethodDescription;
            case DeliveryType.CustomerStays:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(getTimeSlotEnd(timeSlotToDisplay), DateStyle.HH_mm)}`;
            case DeliveryType.SelfDeliverAndPickup:
                return `${workshopContent.timeSlotPickupHeader} ${formatDate(getPickupTime(timeSlotToDisplay.startDateTime), DateStyle.HH_mm)}`;
        }
    }, [deliveryType, selectedTimeSlot, selectedTimeSlotToStay, workshopContent.pickupMethodDescription, workshopContent.timeSlotPickupHeader]);

    const getTime = (): string => {
        const timeSlotToDisplay = deliveryType === DeliveryType.CustomerStays ? selectedTimeSlotToStay : selectedTimeSlot;
        if (!timeSlotToDisplay) return '';

        return formatDate(timeSlotToDisplay.startDateTime, DateStyle.HH_mm);
    };

    return (
        <CompletedWrapper>
            <SubmittedInfo label={workshopContent.workshopNameLabel} text={workshop?.name ?? ''} />
            <SubmittedInfo label={workshopContent.selectedDateLabel} text={formatDate(selectedDate, DateStyle.dk_full_text)} />

            {deliveryType !== DeliveryType.ByVendor && (
                <>
                    <SubmittedInfo label={workshopContent.deliveryTimeLabel} text={getTime()} />
                </>
            )}

            <SubmittedInfo label={workshopContent.pickupMethodLabel} text={pickUpMethodDescription} />
        </CompletedWrapper>
    );
};
