import { FC, useEffect, useMemo, useState } from 'react';
import { DeliveryType } from '../../../../../lib';
import { DatePicker, RadioButton } from '../../../../shared';
import { TimeSlotPicker } from '../../../../time-slot-picker';
import { WorkshopBookingData, DeliveryWrapper, DeliveryMethods, StyledHeader, LabelText, SubText } from './booking-info.styled';
import { BookingWorkshopsStore } from '../../../../../lib/state/booking/workshops';
import { umbraco } from '../../../../../lib/api';
import { BookingFormStore } from '../../../../../lib/state/booking/form';
import { FuelType, TimeSlot } from '../../../../../lib/api/models/hessel-api';
import { Workshop } from '../../../../../lib/state/booking/workshops/booking-workshops.types';
import { isEqual, compareAsc } from 'date-fns';
import { DateStyle, formatDate, getPickupTime, mapTimeOnlyToDuration } from '../../../../../utils/helpers';
import { BookingServiceProductsStore } from '../../../../../lib/state/booking/service-products';

type Props = {
    workshopContent: umbraco.BookingStepWorkshop;
    calendarMonthChange: (date: Date) => void;
};

const getCalendarDays = (deliveryType: DeliveryType, workshop?: Workshop) => {
    if (!workshop) {
        return [];
    }
    const now = new Date();
    const days =
        deliveryType === DeliveryType.SelfDeliverAndPickup
            ? workshop.availableDays.filter((x) => compareAsc(x.date, now) === 1)
            : workshop.availableDaysToStay;

    return days?.filter(({ timeSlots }) => timeSlots.length > 0 && timeSlots.some((t) => t.available))?.map(({ date }) => date);
};

const getEstimationText = (timeSlot?: TimeSlot<Date>) => {
    const duration = timeSlot ? mapTimeOnlyToDuration(timeSlot.duration) : undefined;
    const hours = duration?.hours ?? 0;
    const minutes = duration?.minutes ?? 0;
    if (hours === 0 && minutes === 0) {
        return '-';
    }

    const minuteText = minutes > 1 ? 'minutter' : 'minut';
    const hourText = hours > 1 ? 'timer' : 'time';

    if (hours === 0) {
        return `${minutes} ${minuteText}`;
    }

    if (minutes === 0) {
        return `${hours} ${hourText}`;
    }

    return `${hours} ${hourText} og ${minutes} ${minuteText}`;
};

export const WorkshopBookingInfo: FC<Props> = ({ workshopContent, calendarMonthChange }) => {
    const [isDatePickerModalOpen, setIsDatePickerModalOpen] = useState(false);
    const { selectedDate, selectedWorkshop, deliveryType, selectedTimeSlotToStay, selectedTimeSlot, workshops, customerHasChosenDeliveryType } =
        BookingWorkshopsStore.useStoreState((state) => state);

    const { serviceTooLong } = BookingServiceProductsStore.useStoreState((state) => state);

    const { setSelectedDate, setDeliveryType, setTimeSlotToStay, setTimeSlot, setIsLoadingSelectedWorkshop, setCustomerHasChosenDeliveryType } =
        BookingWorkshopsStore.useStoreActions((actions) => actions);

    const vehicle = BookingFormStore.useStoreState(({ vehicle }) => vehicle);

    const workshop = useMemo(() => workshops.find(({ id }) => id === selectedWorkshop), [workshops, selectedWorkshop]);

    useEffect(() => {
        if (serviceTooLong) {
            setDeliveryType(DeliveryType.SelfDeliverAndPickup);
        }
    }, [serviceTooLong, setDeliveryType]);

    useEffect(() => {
        setIsLoadingSelectedWorkshop(workshop?.loadingDays ?? false);
    }, [setIsLoadingSelectedWorkshop, workshop?.loadingDays]);

    // Available Days To Stay: This is to set calendar for the first time
    useEffect(() => {
        const sortedAvailableDaysToStay = workshop?.availableDaysToStay.sort((a, b) => a.date.getTime() - b.date.getTime());

        const alreadyAvailableDayToStay = sortedAvailableDaysToStay?.find(
            (x) => formatDate(x.date, DateStyle.yyyy_mm_dd) === formatDate(selectedDate, DateStyle.yyyy_mm_dd) && x.timeSlots.some((x) => x.available)
        );

        const availableTimeSlotToStay = alreadyAvailableDayToStay ?? sortedAvailableDaysToStay?.find((x) => x.timeSlots.some((ts) => ts.available));

        if (availableTimeSlotToStay && deliveryType === DeliveryType.CustomerStays) {
            setSelectedDate(availableTimeSlotToStay.date);
        }

        const firstAvailableTimeSlotToStay = availableTimeSlotToStay?.timeSlots.find((ts) => ts.available);

        if (firstAvailableTimeSlotToStay) {
            setTimeSlotToStay(firstAvailableTimeSlotToStay);
        }
    }, [deliveryType, selectedDate, setSelectedDate, setTimeSlotToStay, workshop?.availableDaysToStay]);

    // Available Days: This is to set calendar for the first time
    useEffect(() => {
        const sortedAvailableDays = workshop?.availableDays.sort((a, b) => a.date.getTime() - b.date.getTime());

        const alreadyAvailableDay = sortedAvailableDays?.find(
            (x) =>
                formatDate(x.date, DateStyle.yyyy_mm_dd) === formatDate(selectedDate, DateStyle.yyyy_mm_dd) && x.timeSlots.some((ts) => ts.available)
        );

        const availableTimeSlot = alreadyAvailableDay ?? sortedAvailableDays?.find((x) => x.timeSlots.some((ts) => ts.available));

        if (availableTimeSlot && deliveryType === DeliveryType.SelfDeliverAndPickup) {
            setSelectedDate(availableTimeSlot.date);
        }

        const firstAvailableTimeSlot = availableTimeSlot?.timeSlots.find((ts) => ts.available);

        if (firstAvailableTimeSlot) {
            setTimeSlot(firstAvailableTimeSlot);
        }
    }, [deliveryType, selectedDate, setSelectedDate, setTimeSlot, workshop?.availableDays]);

    useEffect(() => {
        if (deliveryType === DeliveryType.ByVendor && vehicle?.fuelTypeId !== FuelType.EL) {
            setDeliveryType(DeliveryType.SelfDeliverAndPickup);
        }
    }, [vehicle?.fuelTypeId, deliveryType, setDeliveryType]);

    return (
        <WorkshopBookingData isLoading={!isDatePickerModalOpen && (workshop?.loadingDays || false)}>
            <DatePicker
                selectedDate={selectedDate}
                onSelect={(e) => {
                    setSelectedDate(e);

                    const day = workshop?.availableDays.find((x) => formatDate(x.date, DateStyle.yyyy_mm_dd) === formatDate(e, DateStyle.yyyy_mm_dd));

                    if (day && !isEqual(day.date, selectedDate)) {
                        const availableTs = day.timeSlots.find((ts) => ts.available);

                        if (availableTs) {
                            setTimeSlot(availableTs);
                        }
                    }

                    const dayToStay = workshop?.availableDaysToStay.find(
                        (x) => formatDate(x.date, DateStyle.yyyy_mm_dd) === formatDate(e, DateStyle.yyyy_mm_dd)
                    );

                    if (dayToStay && !isEqual(dayToStay.date, selectedDate)) {
                        const availableToStay = dayToStay.timeSlots.find((ts) => ts.available);

                        if (availableToStay) {
                            setTimeSlotToStay(availableToStay);
                        }
                    }
                }}
                availableDays={getCalendarDays(deliveryType, workshop)}
                modalHeaderText={workshopContent.modalHeaderText}
                modalConfirmText={workshopContent.modalConfirmText}
                modalCancelText={workshopContent.modalCancelText}
                onMonthChange={(date) => calendarMonthChange(date)}
                toggleModalNotification={(state) => {
                    setIsDatePickerModalOpen(state);
                }}
                loadingDays={workshop?.loadingDays || false}
                showWeekNumber={true}
                includeWeekends={true}
            />
            <DeliveryWrapper>
                <DeliveryMethods>
                    <StyledHeader>
                        {serviceTooLong ? workshopContent.serviceTooLongHeader ?? '' : workshopContent.deliveryAndPickupHeader}
                    </StyledHeader>

                    {serviceTooLong ? (
                        <div dangerouslySetInnerHTML={{ __html: workshopContent.serviceTooLongExplanation }}></div>
                    ) : (
                        <>
                            <RadioButton
                                id="rbCustomerStays"
                                groupName="deliveryType"
                                value={`${DeliveryType.CustomerStays}`}
                                action={() => {
                                    setCustomerHasChosenDeliveryType(true);
                                    setDeliveryType(DeliveryType.CustomerStays);
                                }}
                                checked={deliveryType === DeliveryType.CustomerStays && customerHasChosenDeliveryType}
                                labelAlignment="top"
                            >
                                <LabelText>{workshopContent.customerStaysLabel}</LabelText>
                                <SubText>
                                    <small>
                                        <b>
                                            {workshopContent.customerStaysSubLabel}: {getEstimationText(selectedTimeSlotToStay)}
                                        </b>
                                    </small>
                                </SubText>
                            </RadioButton>

                            <RadioButton
                                id="rbSelfDeliveryAndPickup"
                                groupName="deliveryType"
                                value={`${DeliveryType.SelfDeliverAndPickup}`}
                                action={() => {
                                    setCustomerHasChosenDeliveryType(true);
                                    setDeliveryType(DeliveryType.SelfDeliverAndPickup);
                                }}
                                checked={deliveryType === DeliveryType.SelfDeliverAndPickup && customerHasChosenDeliveryType}
                                labelAlignment="top"
                            >
                                <LabelText>{workshopContent.customerSelfDeliveryAndPickup}</LabelText>
                                <SubText>
                                    <small>
                                        <b>
                                            {selectedTimeSlot &&
                                                workshopContent.customerSelfDeliveryAndPickupSubLabel &&
                                                `${workshopContent.customerSelfDeliveryAndPickupSubLabel} ${formatDate(
                                                    getPickupTime(selectedTimeSlot.startDateTime),
                                                    DateStyle.HH_mm
                                                )}`}
                                        </b>
                                    </small>
                                </SubText>
                            </RadioButton>

                            {vehicle?.fuelTypeId === FuelType.EL && vehicle?.make === 'MERCEDES-BENZ' && (
                                <RadioButton
                                    id="rbByVendor"
                                    groupName="deliveryType"
                                    value={`${DeliveryType.ByVendor}`}
                                    action={() => {
                                        setCustomerHasChosenDeliveryType(true);
                                        setDeliveryType(DeliveryType.ByVendor);
                                    }}
                                    checked={deliveryType === DeliveryType.ByVendor && customerHasChosenDeliveryType}
                                    labelAlignment="top"
                                >
                                    <LabelText>{workshopContent.byVendorLabel}</LabelText>
                                </RadioButton>
                            )}
                        </>
                    )}
                </DeliveryMethods>

                <TimeSlotPicker deliveryType={deliveryType} workshopContent={workshopContent} />
            </DeliveryWrapper>
        </WorkshopBookingData>
    );
};
