import { FC, useCallback, useEffect, useState } from 'react';
import { YourCarWrapper, YourCarStep } from './your-car.styled';
import { CardAgreements } from './agreements';
import { umbraco } from '../../../../lib/api';
import { BookingFormStore } from '../../../../lib/state/booking/form';
import { formInputValue, getPrefillValuesFromQuery, Prefill, replaceLineBreaks } from '../../../../utils/helpers';
import { Brand } from './brand/brand.component';
import { BookingInputs } from '../../inputs';
import { BookingWorkshopsStore } from '../../../../lib/state/booking/workshops';
import { BookingStepsStore } from '../../../../lib/state/booking/steps';
import { YourCarSummary } from './summary';
import { useUI } from '../../../ui';
import { useGtmTracking } from '../../../../gtm-tracking/hooks/use-gtm-tracking';
import { generateFormId } from '../../../../lib/state/booking/form/booking-form.helpers';
import { UserConsentStore } from '../../../../lib/state/user-consent/user-consent.store';
import { useRouter } from 'next/router';

type UpdateWorkshopArgs = { make: string | undefined; preferredWorkshopId: number | undefined };

type Props = {
    isCompleted: boolean;
    content: umbraco.BookingStepYourCar;
    stepNumber: number;
    canValidateStep: boolean;
};

export const YourCar: FC<Props> = ({ isCompleted, content, stepNumber, canValidateStep }) => {
    const router = useRouter();
    const [customerInfo, setCustomerInfo] = useState({
        email: '',
        licensePlate: '',
    });
    const [lastSentEmail, setLastSentEmail] = useState('');
    const [lastMake, setLastMake] = useState('');
    const [lastSentLicensePlate, setLastSentLicensePlate] = useState('');

    const [workshopParams, setWorkshopParams] = useState<UpdateWorkshopArgs>({ make: undefined, preferredWorkshopId: undefined });

    const { inputs, preferredWorkshopId, make, vehicleClass, carInformationLoading, carInformationLoadStarted } = BookingFormStore.useStoreState(
        (state) => ({
            inputs: state.inputs.filter(({ step }) => step === 'YourCar'),
            preferredWorkshopId: state.vehicle?.preferredWorkshopId,
            make: state.vehicle?.make,
            vehicleClass: state.vehicle?.vehicleClass,
            carInformationLoading: state.vehicleInformationLoading,
            carInformationLoadStarted: state.vehicleInformationLoadStarted,
        })
    );

    const { getCustomer, getVehicle, clearCustomer, clearVehicle, onChange, setCanValidate, onChangeMultiple, setCanValidateAddress } =
        BookingFormStore.useStoreActions((actions) => actions);
    const { loadingWorkshops } = BookingWorkshopsStore.useStoreState(({ loadingWorkshops }) => ({ loadingWorkshops }));
    const { getWorkshopsThunk, setWorkshops, setSelectedWorkshop } = BookingWorkshopsStore.useStoreActions(
        ({ getWorkshopsThunk, setWorkshops, setSelectedWorkshop }) => ({
            getWorkshopsThunk,
            setWorkshops,
            setSelectedWorkshop,
        })
    );

    const { currentStep } = BookingStepsStore.useStoreState((state) => state);
    const { setStepValidity } = BookingStepsStore.useStoreActions((actions) => actions);

    const [showAgreements, setShowAgreements] = useState(false);

    const updateWorkshops = useCallback(
        ({ make, preferredWorkshopId }: UpdateWorkshopArgs) => {
            if (!make) {
                setSelectedWorkshop({ id: undefined, isFavourite: true });
                setWorkshops([]);
            } else {
                getWorkshopsThunk({ make, preferredWorkshopId, vehicleClass });
            }
        },
        [getWorkshopsThunk, setSelectedWorkshop, setWorkshops, vehicleClass]
    );

    useEffect(() => {
        const emailInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Email'));
        const licensePlateInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Car Registration Number'));
        const email = emailInput ? formInputValue(emailInput) : null;
        const licensePlate = licensePlateInput ? formInputValue(licensePlateInput) : null;

        if (email !== customerInfo.email || licensePlate !== customerInfo.licensePlate) {
            setCustomerInfo({
                ...customerInfo,
                email: email ?? '',
                licensePlate: licensePlate ?? '',
            });
        }
    }, [customerInfo, inputs]);

    useEffect(() => {
        const prefillValues = getPrefillValuesFromQuery();
        if (prefillValues.length === 0) return;

        for (const newValue of prefillValues) {
            if (newValue.rootId !== undefined) {
                setCanValidateAddress({
                    ...newValue,
                    rootId: newValue.rootId,
                    value: true,
                });
                onChangeMultiple({
                    ...newValue,
                    rootId: newValue.rootId,
                });
            } else {
                setCanValidate({
                    ...newValue,
                    value: true,
                });
                onChange(newValue);
            }
        }

        const urlParams = new URLSearchParams(window.location.search);
        const prefillProperties: Prefill = { address: '', email: '', fullName: '', licensePlate: '', mobile: '', zip: '' };
        Object.keys(prefillProperties).forEach((key) => delete router.query[key]);

        window.history.replaceState(null, document.title, '?' + urlParams.toString());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const emailInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Email'));
        const licensePlateInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Car Registration Number'));
        if (emailInput?.isFocused || licensePlateInput?.isFocused) return;
        if (lastSentEmail === customerInfo.email && lastMake === make) return;

        if (emailInput?.canValidate && emailInput.isValid && make) {
            setLastSentEmail(customerInfo.email);
            setLastMake(make);
            getCustomer({ email: customerInfo.email, make });
        } else if (lastSentEmail !== '' && lastMake !== '') {
            setLastSentEmail('');
            setLastMake('');
            clearCustomer();
        }
    }, [clearCustomer, customerInfo.email, getCustomer, inputs, lastMake, lastSentEmail, make]);

    useEffect(() => {
        const licensePlateInput = inputs.find(({ id }) => id === generateFormId('YourCar', 'Car Registration Number'));
        if (licensePlateInput?.isFocused || lastSentLicensePlate === customerInfo.licensePlate) return;

        if (licensePlateInput?.canValidate && licensePlateInput?.isValid) {
            setLastSentLicensePlate(customerInfo.licensePlate);
            getVehicle({ licensePlate: customerInfo.licensePlate });
        } else if (lastSentLicensePlate !== '') {
            setLastSentLicensePlate('');
            clearVehicle();
        }
    }, [clearVehicle, customerInfo.licensePlate, getVehicle, inputs, lastSentLicensePlate]);

    useEffect(() => {
        if ((make !== workshopParams.make || preferredWorkshopId !== workshopParams.preferredWorkshopId) && !loadingWorkshops) {
            setWorkshopParams({ make, preferredWorkshopId });
            updateWorkshops({ make, preferredWorkshopId });
        }
    }, [loadingWorkshops, make, preferredWorkshopId, updateWorkshops, workshopParams.make, workshopParams.preferredWorkshopId]);

    useEffect(() => {
        if (currentStep === stepNumber) {
            setStepValidity({ isValid: inputs.every((input) => input.isValid), stepNumber });
        }
    }, [currentStep, setStepValidity, inputs, stepNumber]);

    const ui = useUI();

    const { trackBooking } = useGtmTracking();
    const tracker = trackBooking();

    const { cookieBotAccepted } = UserConsentStore.useStoreState((state) => state);

    useEffect(() => {
        if (currentStep === stepNumber && cookieBotAccepted) {
            tracker.checkoutStep(1, []);
        }
    }, [cookieBotAccepted, currentStep, stepNumber, tracker]);

    if (isCompleted) {
        return <YourCarSummary inputs={inputs} />;
    }

    return (
        <>
            <YourCarWrapper>
                <p dangerouslySetInnerHTML={{ __html: replaceLineBreaks(content.descriptionText) }} />
                <YourCarStep>
                    <BookingInputs inputs={inputs} canValidate={canValidateStep} />

                    <Brand
                        show={carInformationLoadStarted}
                        isLoading={carInformationLoading}
                        showAgreements={() => {
                            setShowAgreements(true);
                            ui.removeScroll();
                        }}
                    />
                </YourCarStep>
            </YourCarWrapper>
            <CardAgreements
                isVisible={showAgreements}
                cancelAction={() => {
                    setShowAgreements(false);
                    ui.applyScroll();
                }}
            />
        </>
    );
};
